import axios from 'axios'

var API_URL = process.env.VUE_APP_BACKEND_URL


export class DataService {
    async getConfig(key) {
        const url = `${API_URL}/data/getConfig?key=${key}`
        return await axios.post(url)
    }

    async registration(data) {
        const url = `${API_URL}/census/register`
        return await axios.post(url, data)
    }

    async setPassword(data) {
        const url = `${API_URL}/census/setPassword`
        return await axios.post(url, data)
    }

    async getProjects(params) {
        const url = `${API_URL}/census/dashboard/getProjects`
        axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
        return axios.post(url, params).then(res => res.data)
    }

    async validatePasswordLink(data) {
        const url = `${API_URL}/auth/validatePasswordLink`
        return await axios.post(url, data) 
    }
}

