<template>
  <div class="main-wrapper">
    <v-flex class="pa-10 pb-8 text-center">
      <v-img
        class="mx-auto"
        max-height="150"
        max-width="250"
        src="../assets/images/logo.png"
      ></v-img>
    </v-flex>

    <div class="text-wrapper">
      <h4 class="text-heading">Free Interactive Dashboard</h4>
      <h1 class="text-heading mt-5">InsightzClub Visual Data Library</h1>
    </div>

    <div class="body-wrapper">
      <v-row>
        <v-col cols="12" md="4" lg="4" sm="4" xl="4">
          <v-btn large @click="goToForm()" rounded color="primary" dark>
            Get Access Now!</v-btn
          >
          <h2 class="text-heading mt-4 mb-4">What will you get?</h2>

          <ul class="bullet-list-wrapper">
            <li>
              Our latest data from local respondents.
            </li>
            <li>
              Free Insights into local market.
            </li>
            <li>
             Free article explaining how we collected the data.
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="8" lg="8" sm="8" xl="8">
          <v-img
            class="mx-auto"
            max-height="540"
            max-width="540"
            src="../assets/images/landing_page.jpg"
          ></v-img>
        </v-col>
      </v-row>
<!-- 
      <v-row class="image-list">
        <v-col cols="12" md="6" lg="6" sm="6" xl="6">
          <v-img
            class="mx-auto"
            max-height="740"
            max-width="740"
            src="../assets/images/age pyramid.png"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" lg="6" sm="6" xl="6">
          <v-img
            class="mx-auto"
            max-height="740"
            max-width="740"
            src="../assets/images/hh expenditure.png"
          ></v-img>
        </v-col>
      </v-row> -->
    </div>

    <v-app-bar id="signup-form" class="mt-10" dark color="#25476E">
      <v-icon large class="mr-3">text_snippet</v-icon>
      <v-toolbar-title>Get Access Now</v-toolbar-title>
    </v-app-bar>
    <v-card>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.firstName"
                label="First Name*"
                :autofocus="true"
                :rules="[(v) => !!v || 'First Name is required']"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.lastName"
                label="Last Name*"
                required
                :rules="[(v) => !!v || 'Last Name is required']"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="user.email"
            label="Email*"
            required
            :rules="emailRules"
          ></v-text-field>

          <v-autocomplete
            :items="countries"
            v-model="user.countryCode"
            label="Country"
            item-text="title"
            item-value="code"
            autocomplete
            required
            :rules="[(v) => !!v || 'Country is required']"
          >
          </v-autocomplete>

          <v-text-field
            :prefix="user.countryCode"
            v-model="user.mobileNumber"
            label="Phone Number (Optional)"
          ></v-text-field>

          <v-text-field
            v-model="organization.name"
            label="Company*"
            required
            :rules="[(v) => !!v || 'Company is required']"
          ></v-text-field>

          <v-select
            v-model="organization.companyType"
            :items="companyType"
            required
            :rules="[(v) => !!v || 'Company Type is required']"
            label="Company Type*"
          ></v-select>

          <v-text-field
            v-model="user.jobTitle"
            label="Job Title*"
            required
            :rules="[(v) => !!v || 'Job Title is required']"
          ></v-text-field>
        </v-form>

        <v-btn @click="signUp()" class="mt-5" dark color="#19647E"
          >Sign Up</v-btn
        >
      </v-container>
    </v-card>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5"> Success </v-card-title>

        <v-card-text>
          You have successfully created your account. Please check your email.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <footer>
      <p></p>
      <p>Copyright © 2021 InsightzClub. All Rights Reserved.</p>
    </footer>
  </div>
</template>

<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/20316275.js"></script>
<script>
import { DataService as CensusDataService } from "./../services/CensusDataService";
const censusDataService = new CensusDataService();
export default {
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (value) => !!value || "Password required.",
        (value) => (value || "").length >= 8 || `Min 8 characters`,
      ],
      showPassword: false,
      user: {
        registrationType: 'census',
        roles: ["USER"],
      },
      countries: [],
      mobileNumberRules: [(value) => !!value || "Phone number required."],
      organization: {},
      companyType: [
        "Academic",
        "Automotive",
        "B2B",
        "Consumer Goods",
        "Entertainment",
        "Finance / Insurance",
        "Government",
        "Health Care",
        "Media/ Advertising / Marketing",
        "Retail",
        "Telco",
        "Travel / Leisure",
        "Others"
      ],
      dialog: false,
    };
  },

  mounted() {
    this.$loadScript("//js.hs-scripts.com/20316275.js")
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    this.getCountries();
  },

  methods: {
    getCountries() {
      censusDataService.getConfig("countries").then((res) => {
        this.countries = res.data;
      });
    },

    signUp() {
      let cookie = $cookies.get("hubspotutk");
      if (this.$refs.form.validate()) {
        this.$setLoader();
        censusDataService
          .registration({
            user: this.user,
            organization: this.organization,
            cookie: cookie,
          })
          .then(() => {
            this.$clearLoader();
            this.dialog = true;
          });
      }
    },

    goToForm() {
      document.getElementById("signup-form").scrollIntoView();
    },
  },
};
</script>


<style lang="scss" scoped>
.text-heading {
  color: #00326d;
}

.text-wrapper {
  margin-top: 5%;
}

.body-wrapper {
  margin-top: 5%;
  margin-bottom: 20px;
}

.bullet-list-wrapper {
  color: #00326d;
}

.bullet-list {
  font-weight: bold;
}

ul li {
  padding: 11px 0px;
}

.main-wrapper {
  margin-left: 10%;
  margin-right: 10%;
}

.image-list {
  margin-top: 5%;
}

footer {
  background-color: white;
  padding: 10px;
  text-align: center;
  color: black;
}

html {
  scroll-behavior: smooth;
}
</style>